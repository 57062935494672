import { APIfetch } from "./APICONFIG";

export default async function CallAPI(method, url, body = {}) {
  try {
    let resturl = APIfetch.baseURL + url;
    //console.log("api ", resturl);
    let config = APIfetch.config;
    config["method"] = method;
    if (method === "POST") {
      config.body = body;
    }
    //console.log(config);
    //const respond = await APICONFIG(config);
    const response = await fetch(resturl, config);
    const txt_response = await response.json();
    ////console.log("respon", txt_response);
    return txt_response;
  } catch (e) {
    //console.log("callapi error : ", e);
  }
}
