import React, { useState, useEffect } from "react";
import "../App.css";
import { Tabs, Tab, Container, Row } from "react-bootstrap";
import Cellprotofolio, { MyModal } from "../component/cellprotofolio";
import { REST } from "../services/keys_api";
import CallAPI from "../services/api";
const PortoContainer = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const setShowModal = (item) => {
    setModalData(item);
    //console.log(item);
    setTimeout(() => {
      setModalShow(true);
    }, 1000);
  };
  return (
    <Container fluid>
      <div className="CenterText TextSubTitle">{props.title}</div>

      <br />
      <Row style={{ justifyContent: "center" }}>
        {props.data.map((res, index) => (
          <Cellprotofolio
            key={`portp${index}`}
            {...props}
            data={res}
            onShow={setShowModal.bind(this)}
          />
        ))}
      </Row>
      {modalData && (
        <MyModal show={modalShow} data={modalData} onHide={() => setModalShow(false)} />
      )}
    </Container>
  );
};
//
function ContainerPortofolio(props) {
  return (
    <Container fluid>
      <div className="App-link Porto-Center">
        <div className="porto-top ">
          {/* <div className="TextTitleContainer">
            <text className="TextTitlePorto">{props.title}</text>
          </div> */}
          <br />
          {<PortoContainer data={props.data} title="" />}
          <br />
        </div>
      </div>
    </Container>
  );
}
//
function Porto(props) {
  const [tabIndex, setTabIndex] = useState("Apps");
  const [appState, setAppState] = useState({ loading: false, repos: null });
  useEffect(() => {
    setAppState({ loading: true });
    // const apiurl = "https://raw.githubusercontent.com/fajardsx/Cfg_Repo/master/cfg_web.json";
    // axios.get(apiurl).then((result) => {
    //   const resultData = result.data;
    //   setAppState({ loading: false, repos: resultData });
    // });
    callWork();
  }, [setAppState]);
  async function callWork() {
    const apiurl = REST.API_WORK; //"https://raw.githubusercontent.com/fajardsx/Cfg_Repo/master/cfg_web.json";
    const respond = await CallAPI("GET", apiurl);
    //console.log("ResultData", respond);
    if (respond && respond.status === 200) {
      setAppState({ loading: false, repos: respond.result });
    }
  }
  return (
    <div className="App-header">
      {appState.repos && (
        <Tabs activeKey={tabIndex} onSelect={(k) => setTabIndex(k)}>
          <Tab eventKey={"Apps"} title="Apps">
            <ContainerPortofolio data={appState.repos} title={"Apps"} />
          </Tab>
        </Tabs>
      )}
    </div>
  );
}

export default Porto;
