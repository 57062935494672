import React from "react";
//import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
//import { Button } from "react-bootstrap";
import "./App.css";
import Home from "./page/home";
import { Switch, Route, Link } from "react-router-dom";
import Porto from "./page/porto";

// const routeconfig = [
//   {
//     path: "/home",
//     component: Home,
//   },
//   {
//     path: "/porto",
//     component: Porto,
//   },
// ];

const HeaderNavBar = () => (
  <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    <Navbar.Brand as={Link} to="/" style={{ justifyItems: "center" }}>
      {/* <img
        alt=""
        src={logo}
        width="40"
        height="40"
        className="d-inline-block"
      />{" "} */}
      FAJAR MANDARI
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto" />
      <Nav>
        <Nav.Link as={Link} to="/">
          HOME
        </Nav.Link>
        <Nav.Link as={Link} to="/portofolio">
          PORTOFOLIO
        </Nav.Link>
        {/* <Nav.Link href="#about">ABOUT</Nav.Link> */}
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);
//
// function RouteWithRoutes(route) {
//   //console.log(route);
//   return (
//     <Route
//       path={route.path}
//       render={(props) => <route.component {...props} routes={route.routes} />}
//     />
//   );
// }
//
const App = () => (
  <div className="App-header">
    <HeaderNavBar />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/portofolio" component={Porto} />
      {/* <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      {routeconfig.map((route, i) => (
        <RouteWithRoutes key={i} {...route} />
      ))} */}
    </Switch>
    <div className="App-link Porto-copyright">
      <div className="CenterText">Copyright © fajardsx 2023</div>
    </div>
  </div>
);
export default App;
