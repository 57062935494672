import axios from "axios";
import Constant from "../Constant";

export const Axios = axios.create({
  baseURL: Constant.LINK2,
  responseType: "json",
});

export const APIfetch = {
  baseURL: Constant.LINK,
  config: {
    //mode: "cors",
    headers: {
      Accept: "*",
      "Content-Type": "text/plain",
    },
  },
};
