import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import "../App.css";
import "./cell.css";
//import Constant from "../Constant";
import zom from "../assets/zoomicon.svg";
import { Modal, Carousel, ProgressBar, Row, Col } from "react-bootstrap";
import Constant from "../Constant";

// CELL
export default class Cellprotofolio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHover: false,
    };
  }

  render() {
    return (
      <div
        className="CellPorto"
        onClick={() => this.props.onShow(this.props.data)}
        onMouseEnter={() => this.setState({ isHover: true })}
        onMouseLeave={() => this.setState({ isHover: false })}>
        <Image
          className="w-100 bannerPortoSize align-self-start"
          src={Constant.LINK + Constant.API_CONTENT + this.props.data.gallery[0].images}
          rounded
        />
        <div className="PortotitleContainer">
          <span>{`${this.props.data.name}`}</span>
        </div>
        {this.state.isHover && (
          <div className="PortoHover">
            <Image src={zom} height={50} width={50} alt="Details" />
          </div>
        )}
      </div>
    );
  }
}

export function MyModal(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered animation>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{`${props.data.name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="MainGallery">
          <Carousel slide={false} touch={true} pause controls={true}>
            {props.data.gallery.map((res, index) => (
              <Carousel.Item key={"gallery" + index}>
                <img
                  key={"gallery" + index}
                  className="d-block w-100 bannerSize"
                  src={Constant.LINK + Constant.API_CONTENT + res.images}
                  alt={`gallery${index}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        Description :<p>{`${props.data.desc}`}</p>
        <Row>
          <Col>
            <p>{`Type : ${props.data.type}`}</p>
          </Col>
          <Col>
            <p>{`Platform : ${props.data.platform}`}</p>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <p>{`Status : ${props.data.status}`}</p>
          </Col>
          <Col>
            {props.data.link && (
              <p>
                Link : <a href={props.data.link}>Download</a>
              </p>
            )}
          </Col>
        </Row> */}
      </Modal.Body>
    </Modal>
  );
}
const getStatusSkill = (level) => {
  if (level > 70) {
    return "success";
  } else if (level > 30 && level < 70) {
    return "warning";
  } else if (level < 30) {
    return "danger";
  }
};
export function SkillCell(props) {
  return (
    <div id={"skill_cell"}>
      <Row noGutters className="titleskillcell" xl={3} lg={3} md={2} sm={1} xs={1}>
        {props.data.map((res, index) => (
          <Col key={`skill${index}`}>
            <Row id="skillContainer">
              <Image roundedCircle className="skill_img" src={res.uri} />
              <Col sm>
                <div style={{ paddingBottom: 15 }}>{res.name}</div>

                <ProgressBar
                  striped={false}
                  animated
                  variant={getStatusSkill(res.level)}
                  width="80%"
                  now={res.level}
                />
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
}
