import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import "../App.css";

import Cellprotofolio, { MyModal, SkillCell } from "../component/cellprotofolio";
import { Image } from "react-bootstrap";
import Constant from "../Constant";
import CallAPI from "../services/api";
//import CallAPI from "../services/API";
import { REST } from "./../services/keys_api";

//
const MyBanner = (props) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectIndex, e) => {
    setIndex(selectIndex);
  };

  return (
    <Carousel
      interval={3000}
      slide={true}
      touch={true}
      pauseonhover="false"
      controls={false}
      activeIndex={index}
      onSelect={handleSelect}>
      {props.data &&
        props.data.map((res, index) => (
          <Carousel.Item key={"bannermodal" + index}>
            <img
              className="d-block w-100 bannerSize"
              src={`${Constant.LINK + Constant.API_CONTENT + res.gallery[0].images}`}
              alt={`${res.name}`}
            />
            <Carousel.Caption>
              <h3>{`${res.name}`}</h3>
              <p>{`${res.desc}`}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
    </Carousel>
  );
};
const WelcomeContainer = (props) => {
  return (
    <Container fluid>
      <br />

      <Col>
        <div className="CenterText TextTitleWelcome">WELCOME</div>
        <div className=" BorderWelcome" />
      </Col>
      <br />
      <Col>
        <div className="CenterText TextTitleWelcomeDes">
          {
            "Hi,thanks for coming to my website,\n this web is place to show my work,\n I hope you like and maybe we can make connection later."
          }
        </div>
      </Col>
    </Container>
  );
};
const PortoContainer = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const setShowModal = (item) => {
    setModalData(item);
    //console.log("item", item);
    setTimeout(() => {
      setModalShow(true);
    }, 1000);
  };
  return (
    <Container fluid>
      {/* <div className="CenterText TextSubTitle">{props.title}</div>

      <br /> */}
      <Row style={{ justifyContent: "center" }}>
        {props.data &&
          props.data.map((res, index) => {
            if (res.isHome === "1") {
              return (
                <Cellprotofolio
                  key={"porto" + index}
                  {...props}
                  data={res}
                  onShow={setShowModal.bind(this)}
                />
              );
            }
          })}
      </Row>
      {modalData && (
        <MyModal show={modalShow} data={modalData} onHide={() => setModalShow(false)} />
      )}
    </Container>
  );
};

const AboutDiv = (props) => {
  return (
    <>
      <Container id="about">
        <div className="CenterText">
         
        </div>
        <br />
        <Row>
          <Col sm>
            <p>
              HI,My Name is Fajar M, before I know programing, I learn computer programming from
              books and internet. Now I was working as Mobile Programer . I am good using React
              native and ActionScript 3 but also know other programming such as Flutter,
              Unity3d,Php,Html,C#,and Java Android, but I believe I still have interest to learn
              those.
            </p>
          </Col>
        </Row>
        <div className="CenterText TextTitleWelcome">Skill</div>
        <div className=" BorderWelcome" />
        {props.data && props.data.skill && (
          <div>
            <SkillCell data={props.data.skill} />
          </div>
        )}
      </Container>
    </>
  );
};

const Footer = (props) => {
  return (
    <div className="ContactContainer">
      <div className="ContactItem">
        <div className="CenterText TextContact">CONTACT ME</div>
        <br />
        <a href={"mailto:fajardsx@gmail.com"}>
          <div className="CenterText TextContactDesc">fajardsx@gmail.com</div>
        </a>
      </div>
    </div>
  );
};

const Home = () => {
  const [appState, setAppState] = useState({
    loading: false,
    repos: { banner: [], work: [], skill: [] },
  });
  useEffect(() => {
    setAppState({ loading: true });
    callBanner();
  }, [setAppState]);
  async function callBanner() {
    const apiurl = REST.API_BANNER; //"https://raw.githubusercontent.com/fajardsx/Cfg_Repo/master/cfg_web.json";
    const respond = await CallAPI("GET", apiurl);
    //console.log("ResultData", respond);
    if (respond && respond.status === 200) {
      const temprep = appState.repos;
      temprep.banner = respond.result;
      setAppState({ loading: false, repos: temprep });
    }

    // axios.get(apiurl).then((result) => {
    //   const resultData = result.data;
    //   ////console.log("ResultData",resultData)
    //   setAppState({ loading: false, repos: resultData });
    // });
    callWork();
  }
  async function callWork() {
    const apiurl = REST.API_WORK; //"https://raw.githubusercontent.com/fajardsx/Cfg_Repo/master/cfg_web.json";
    const respond = await CallAPI("GET", apiurl);
    //console.log("ResultData", respond);
    if (respond && respond.status === 200) {
      const temprep = appState.repos;
      temprep.work = respond.result;
      //console.log("temprep", temprep);
      setAppState({ loading: false, repos: temprep });
    }
    callSkill();
  }
  async function callSkill() {
    const apiurl = REST.API_SKILL; //"https://raw.githubusercontent.com/fajardsx/Cfg_Repo/master/cfg_web.json";
    const respond = await CallAPI("GET", apiurl);
    //console.log("ResultData", respond);
    if (respond && respond.status === 200) {
      const temprep = appState.repos;
      temprep.skill = respond.result;
      //console.log("temprep", temprep);
      setAppState({ loading: false, repos: temprep });
    }
  }

  return (
    <>
      {appState.repos && <MyBanner data={appState.repos.banner} />}
      <div className="App-link Porto-Center">
        <div className="porto-centerpart">
          <WelcomeContainer />
        </div>
      </div>
      <div className="App-link Porto-Center">
        <div className="porto-top ">
          <div className="TextTitleContainer">
            <div className="TextTitlePorto">MY PORTFOLIO</div>
          </div>
          <br />
          {appState.repos && (
            <PortoContainer key={"apps"} data={appState.repos.work} title="Product" />
          )}

          <br />
          <div className=" mb-2 CenterText">
            <a id="MorePorto" href="/portofolio">
              <h3>MORE PORTFOLIO</h3>
            </a>
          </div>
          <br />
        </div>
      </div>

      <div className="App-link Porto-Center">
        <div className="porto-centerbottom TextTitle">
          <div className="TextTitleContainer ">
            <div className="TextTitleAbout">ABOUT ME</div>
          </div>
          <br />
          <AboutDiv data={appState.repos} />
        </div>
      </div>
      <div className="App-link Porto-Center">
        <div className="porto-footer">
          <br />
          <Footer />
        </div>
      </div>
    </>
  );
};
export default Home;
